<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-unlock"></i> เครื่องมือจัดการระบบ </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <div>
            <b-tabs content-class="mt-3">
              <b-tab title="Diagnostics" active>
                <pageDiagnostics/>
              </b-tab>
              <b-tab title="Database MGT">
                <pageDatabase/>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import pageDatabase from "./view/page.database.vue";
  import pageDiagnostics from "./view/page.diagnostics.vue";

  export default {
    components: {
      pageDatabase,
      pageDiagnostics
    },
    data() {
      return {
      }
    }
  };
</script>
<style>

</style>
