<template lang="">
    <div>
        <div class="mb-3">
            <button class="btn btn-primary" @click="loadProductImgInfo()">Load Data</button>
            <button class="btn btn-warning" @click="reUploadProcess()" :disabled="items.length==0">Process</button>
            <button class="btn btn-danger" @click="process = !process"><span v-if="process">Running</span><span v-else>Stopped</span></button>
        </div>
        <div>
            <table class="table table-sm ">
                <thead>
                    <tr>
                        <th class="text-center">Product ID</th>
                        <th class="text-center">Code</th>
                        <th class="text-center">Total Photo</th>
                        <th class="text-center">Have Original</th>
                        <th class="text-center">Have MIddle Size</th>
                        <th class="text-center">Have Tiny Size</th>
                        <th class="text-center">Data</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="text-center">{{item.id}}</td>
                        <td class="text-center">{{item.code}}</td>
                        <td class="text-center">{{item.lentgh}}</td>
                        <td class="text-center">{{item.url!=null?"Yes":"-"}}</td>
                        <td class="text-center">{{item.url_m!=null?"Yes":"-"}}</td>
                        <td class="text-center">{{item.url_t!=null?"Yes":"-"}}</td>
                        <td>
                            <table class="table table-sm table-bordered">
                                <tbody>
                                    <tr v-for="(img, index) in item.imgs" v-bind:key="index" v-bind:class="[img.reupload ? 'text-primary' : '', 'text-danger']">
                                        <td class="text-center" >{{img.reupload}}</td>
                                        <td class="text-center" >{{img.id}}:{{img.mime_type}}</td>
                                        <td class="text-right text-primary" >{{Math.round(img.size/1000)}} KB</td>
                                        <td class="text-center">{{img.id_t}}:{{img.mime_type_t}}</td>
                                        <td class="text-center">{{img.id_m}}:{{img.mime_type_m}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import SessionStorageUtils from '../../../util/sessionStorageUtils';
    import StringUtils from '../../../util/StringUtils';
    export default {
        name: 'tool-view-database',
        data () {
            return {
                process : true,
                user: {},
                items: {}
            }
        },
        methods: {
            async loadProductImgInfo () {
                const result = await this.HttpServices.getData(`/ab_tools/loadProductImgInfo`);
                if(result&&result.status.code=="200"){
                    this.items = result.data;
                    for(let i in this.items){
                        this.items[i].imgs = JSON.parse(this.items[i].imgs);
                        if(this.items[i].imgs != null){
                            this.items[i].lentgh = this.items[i].imgs.length;
                            for(let j in this.items[i].imgs){
                                this.items[i].imgs[j].reupload = false;
                                if (typeof this.items[i].imgs[j].id != "undefined" && typeof this.items[i].imgs[j].id_m == "undefined"){
                                    this.items[i].imgs[j].reupload = true;
                                }
                            }

                        }
                    }
                }else{
                    this.items = [];
                }
            },
            async resizeImage (img) {
                let param = {
                    imgs : JSON.stringify(img)
                }
                const result = await this.HttpServices.postFormData(`/ab_tools/improveImageMiddleSize`,param);
                if(result&&result.status.code=="200"){
                    console.log(result);
                    return result;
                }
            },
            async reUploadProcess () {
                let reload = this.reUploadProcess;
                const f = new FileReader();
                if(this.items.length>0 && this.process){
                    let item = this.items.shift();
                    let imgs = JSON.parse(JSON.stringify(item.imgs));
                    let updated = false;
                    for(let i in imgs){
                        if(imgs[i].url != null){
                            if(typeof imgs[i].url_m == "undefined"){
                                let data = await this.resizeImage(imgs[i]);
                                let file_path = "http://localhost:8080/webapp.appbit/appbit_api/upload/"+data.data;
                                
                                let f = await fetch(file_path).then(r => r.blob());
                                const s3Auth = await this.HttpServices.authenS3();
                                if(s3Auth._info){
                                    const medias = [];
                                    const accessToken = s3Auth._info.secret.accessToken;
                                   
                                    let params = new FormData();
                                    //const mediaThumbM = await StringUtils.resizeBlobToFile({file: f, maxSize: 400});
                                    params.append("file", f);
                                    params.append("title", data.data);
                                    params.append("file_category", 'products');
                                    
                                    let resultThumbM = await this.HttpServices.postFormDataS3(params, accessToken);
                                    if(resultThumbM.media&&resultThumbM.media.length>0){
                                        const mediaThumbM = resultThumbM.media[0];
                                        imgs[i].id_m = mediaThumbM.id;
                                        imgs[i].original_name_m = mediaThumbM.original_name;
                                        imgs[i].mime_type_m = mediaThumbM.mime_type;
                                        imgs[i].size_m = mediaThumbM.size;
                                        imgs[i].url_m = mediaThumbM.url;
                                    }
                                }
                                updated = true;
                            }
                        }
                    }

                    if(updated){
                        let param = {
                            id : item.id,
                            imgs : JSON.stringify(imgs)
                        }
                        console.log(param);
                        const result = await this.HttpServices.postFormData(`/ab_tools/UpdateProductImageMiddleSize`,param);
                        
                         //await this.HttpServices.postFormData(`/ab_product/upload/${this.item.id}`, params);
                        if(result&&result.status.code=="200"){
                            console.log(param);
                        }
                        
                        /*
                        const result = await this.HttpServices.postFormData(`/ab_tools/improveImageMiddleSize`,param);
                        
                         //await this.HttpServices.postFormData(`/ab_product/upload/${this.item.id}`, params);
                        if(result&&result.status.code=="200"){
                            alert(param.id);
                            console.log(param);
                        }
                        */
                    }
                    

                    await setTimeout(function(){
                        
                        // Todo
                        // 1. Download Images
                        // 2. Upload A
                        // 2. Upload B
                        // 2. Upload C
                        // 3. Change Database Data
                        // 5. Remove Item




                        reload();
                    }, 1);
                }
                
                /*
                const result = await this.HttpServices.getData(`/ab_tools/loadProductImgInfo`);
                if(result&&result.status.code=="200"){
                    this.items = result.data;
                }else{
                    this.items = [];
                }
                */
            },
            onBackHandler(){
                this.$router.push("/");
            },
        },
        async mounted() {
        const user = await SessionStorageUtils.decodeUser();
        this.user = user;
        }
  }
</script>
<style lang="">

</style>
  